.swiper {
  /* display: inline-block; */
  /* width: 100%; */
  /* height: 837px; */
}

.swiper-slide {
  font-size: 18px;
  background: rgb(255, 189, 189);
}

.swiper-slide img {
  /* display: block; */
  object-fit: cover;
}

.slick-next {
  /* right: 0 !important; */
  /* display: none !important; */
}
