$color_gray:#828282;
$title-font-size:10rem;
$font-ul:"NBGothicUL";
.color_gray{
  color:$color_gray;
}
.font_UL{
  font-family: $font-ul !important;
}

// 1024px ~
.contents_section{
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10%;
  
  &.tech_story{
    padding: 10% 10% 2% 10%;
  }
  &.tech_story{
    justify-content: center;
    width: 100%;
    background-image: url("../images/tech/애니퍼피 배경.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    flex-direction: column;
  }
}

.technology{
  .tech_history_header{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    margin-left: 10%;
    > p{
      margin-bottom: 1rem;
    }
  }
  .tech_history_carousel_div{
    width: 100%;
    max-width: 1366px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .tech_history_carousel{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 6rem 0;
    .tech_history_item{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30vw;
      .date{
        font-size: 1.2rem;
        font-style: $font-ul;
      }
      .tech_history_item_title{
        margin: 2rem;
        font-size: 1.3rem;
      }
      img{
        margin: 3rem auto;
        max-width: 40rem;
        width: 70%;
      }
    }
  }
  .img_grey_line{
    width: 90%;
    align-self: center;
  }
  .best{
    margin: 7rem 0;
  }
  .best_tech_ul{
    display: flex;
    justify-content: center;
    align-items: center;
    >li{
      display: flex;
      flex-direction: row;
    }
    .best_tech_li_img{
      width: 22vw;
      max-width: 20rem;
    }
    .best_tech_li_text{
      margin:1rem;
      font-size: 1.7rem;
    }
  }
  .best_title{
    margin-bottom: 3rem;
    width: 100%;
  }
  .partners_item{
    max-height: 7rem;
    width: 90% !important;
    margin: 2rem;
    object-fit: contain;
  }
  .partners_banner{
    margin: 15rem 0 0 0;
  }
}

.front{
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 60rem;
  color: white;
  display: flex;
  align-items: flex-end;
  object-fit: contain;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 65% 50%;
  align-items: flex-start;
  justify-content: center;

  &.faq{background-image:  url("../images/faq/faq-background-pc.png");}
  &.brand_story{
    background-image:  url("../images/bs/brand-story-background.png");
  }
  &.tech_story{
    background-image:  url("../images/tech/tech-story-background.png");
  }
}
h1{
  font-size: 3rem;
}
h2{
  font-size: 3rem;
}
h3{
  font-size: 3rem;
}

.sub-main-img{
width: 100%;
}


.slogan_div{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-bottom: 25%;

  .slogan_img{
    min-width: 40rem;
    width: 30%;
  }
  .sub_slogan_img{
    min-width: 25rem;
    width: 23%;
    margin-bottom: 2rem;
    &.faq{
      width: 15% !important;
    }
  }
  .sub_slogan{
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
  }

}

.bs1{
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  width: 100%;
  overflow: hidden;

  .slogan{
    max-width: 45rem;
    width: 80%;
    margin-bottom: 2rem;
  }

  .bs1_text{
    max-width: 1320px;
    width: 100%;
  }
  .bs1_text>*{
    font-family: "NBGothicUL";
    line-height: 1.5;
    letter-spacing: 0px;
    margin-bottom: 3rem;
    z-index: 1;
    font-size: 1.5rem;
  }
  


>div{
  width: 1320px;
  margin: 0 100px;
}
  >div>*{
    position: relative;
    z-index: 3;
  }
  .graphic{
    width: 70%;
    position: absolute;
    object-fit: cover;
    bottom:-50rem !important;
  }
  .hand_graphic{ 
    right: -15vw
  }
  .dog_hand_graphic{
    left:-15vw;
  }

} //.bs1

.bs2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bs_history{
    display: flex;
    position: relative;
    justify-content: center;
    font-size: 1.5rem;
    p{
      line-height: 1.5;
      position: absolute;
      z-index: 1;
      top: 5%;
      left: 3%;
      text-align: left;
      color: white;

      &.align_right{
        text-align: right;
      }
    }//p

    &.reverse{
      p{
        letter-spacing: 0px;
        line-height: 1.5;
        top: 5%;
        right: 3%;
      }
    }//.reverse
    
    > div{
      position: relative;
      margin-bottom: 1rem;
    }

    .img_story{
      max-width: 1320px;
      width: 100%;
      border-radius: 5rem;
      box-shadow: 10px 10px 20px 4px rgb(206, 206, 206);
      margin-bottom: 2rem;
    }

    &.black{
      p{
        color: black;
        font-weight: 600;
      }
    }

  }//.bs_history
  
}//.bs2



.bs3{
  font-family: "NBGothicUL";
  background-color: #FBF4E1;
  font-size: 3rem;
  line-height: 1.5;
  // font-weight: bold;
  flex-direction: column;
  >div{
    h2{
      margin-bottom: 2rem;
    }
  }

}// .bs3

.keep_going{
  background-image: url("../images/bs/bs_bottom_image.jpg");
  background-position:30% 50%;
  object-fit: contain;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: flex-end;
  position: relative;
  height: 100vh;

  .keep_going_slogan{
    width: 70%;
    max-width: 90rem;
    min-width: 30rem;
    margin-bottom: 3rem;
  }

  >div{
    >*{margin-bottom: 2rem;}
    color: #2b2113;
    padding: 10rem 0;
    font-size: 3rem;
    font-family: "NBGothicUL";
    font-weight: 500;
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    
    >button{
      font-family: "NBGothicUL";
      font-size: 2rem;
      padding: 1.5rem;
      border-radius: 10rem;
      background-color: white;
    }
  }

  .slogan_bottom_text{
    max-width: 20rem;
    min-width: 10rem;
    width: 18%;
    position: absolute;
    left: 7%;
    bottom: 20%;
  }
}

.bs5{
  padding: 2.3rem;

  .slogan_bottom{
    width: 100%;
    max-width: 35rem;
  }
}

// tablet 768px - 1023px
.tablet{
  .contents_section{
    display: flex;
    justify-content: center;
    text-align: center;
    // font-size: 1.8rem;
    // padding: 5rem 3rem;
  }

  .bs1{
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    >div>*>:not(.graphic){
      padding-left: 0;
    }
  }
  // .bs1_text{
  //   padding-left: 10%;
  // }
  .bs2{
    .bs_history{
      p{
        // left: 10%;
      }
      &.reverse{
        p{
          top: 5%;
          // right: 15%;
        }
      }//.reverse
    }
  }
}

// mobile 0px - 767px
.mobile{
  .front{
    justify-content: center;
    align-items: flex-end;

    &.brand_story{
      background-image:  url("../images/bs/brand-story-background.png");
      background-position: 50% 50%;
    }
    &.faq{
      background-image:  url("../images/faq/faq-background-pc.png");
    }
    
  }

  .technology{
    .best_tech_ul{
      flex-direction: column;
      strong{
        font-size: 1.7rem;
      }
      .best_tech_li_text{
        font-size: 1.5rem;
      }
    }
    .tech_history_header{
      margin: auto !important;
    }
    .tech_history_item_title{
      font-size: 1.6rem !important; 
    }
    .best_tech_li_img{
      max-width: none;
      width: 30vw;
    }
  }
  
  .contents_section{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    padding: 5rem 3rem;
  }
  
  .bs1{
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    .bs1_text>*{
      padding-left: 5%;
    }
    >div{
      margin: 0 0;
    }
  }
  .bs2{
    // padding-left: 0;
    // padding-right: 0;
    .bs_history{
      // width: 90%;
      .img_story{
        // max-width: 90rem;
        // min-width: 50rem;
        width: 100%;
        border-radius: 3rem;
        box-shadow: 10px 10px 10px 2px rgb(206, 206, 206);
      }
      
    }
  }
}