$blue-color:#1282EE;
$gray-color:#999999;
$light-gray-color:#F5F5F5;
$light-blue-color:#F1FAFF;
$font-light:"NBGothicLight";
$font-bold:"NBGothicBold";
$font-default:"NBGothic";
$font-light:"NBGothicLight";
$yellow-color:#FFD662;
$light-purple-color:#d3adff;

*{
    line-break: strict;
    word-break: keep-all;
}
.App{
    background: #ffffff;
    width: 100%;
}

.privacy, .terms {
    font-size: 1.0rem;
    margin: 2rem;
    h1 {
        font-size: 1.2rem;
        margin-bottom: 1.5rem; /* H1 아래에 간격 추가 */
    }

    h2 {
        font-size: 1.1rem;
        margin-top: 1.5rem; /* H2 위에 간격 추가 */
        margin-bottom: 1rem; /* H2 아래에 간격 추가 */
    }

    h3 {
        font-size: 1.1rem;
        margin-top: 1.5rem; /* H2 위에 간격 추가 */
        margin-bottom: 1rem; /* H2 아래에 간격 추가 */
    }

    p {
        // margin-bottom: 1.2rem; /* 문단 사이 간격 추가 */
        text-indent: 1.5rem; /* 들여쓰기 추가 */
    }

    ol, ul {
        margin-bottom: 1.2rem; /* 목록 사이 간격 추가 */
        padding-left: 1.5rem; /* 목록 들여쓰기 추가 */
    }

    li {
        margin-bottom: 0.8rem; /* 리스트 항목 사이 간격 추가 */
    }
}


.hanja{
    font-family: Helvetica, sans-serif !important;
    font-weight: 500;
}

.not_found{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    font-size: 2.0rem;
    text-align: center;
    >*{
        margin-bottom: 2rem;
    }
    >img{
        width: 60%;
        object-fit: contain;
        max-width: 80rem;
    }
    >a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:$gray-color;
    }
    .btn404{
        width: 12rem;
        height: 3rem;
        background-color: $blue-color;
        color: white;
        border-radius: 2.0rem;
        font-size: 1.5rem;
        margin-top: 2rem;
    }
}

.background-light-blue{
    background-color: $light-blue-color;
}
.background-light-gray{
    background-color: $light-gray-color;
}
.body{
    margin: 0 auto;
    max-width: 1140px;
    justify-content: center;
    font-family: $font-default;
    font-size: 2.5rem;
    
}

.page{
    width: 100%;
    height: 100vh;
    display: inline;
}

.right_align{
    text-align: right;
}

.left_align{
    text-align: left;
}

.center_align{
    text-align: center;
}

.social_icon{
    width: 4.0rem;
}

.blue-button{
    background-color: $blue-color;
    width: 30rem;
    color: white;
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1.2rem;
}

/* ==========header============ */


.header_menu{
    display: flex;
    flex-direction: row;
    color: black;
    .sandbox_btn{
        cursor: pointer;
    }
    li{
        display: flex;
        align-items: center;
        
    }
}

.anipuppy_logo_img{
    min-width: 13rem;
    max-width: 13rem;
    width: 90%;
}

.shield{
    width: 100%;
}
//=====================================================

.gray{
    background-color: #F9F9F9;
}

.h100{
    height: 100vh;
}
.h50{
    height: 50vh;
}
.section{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contents{
    padding: 10rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.relative{
    position: relative;
    width: 90%;
}
.div_nose{
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 30px;
}

.nose{
    position: static;
    z-index: 2;
}

.nose_line{
    width: 220px;
    position: absolute;
    z-index: 3;
    top:17%;
    animation: blink-effect 1s step-end infinite;
    -webkit-animation:blink-effect 1s step-end infinite;
    -moz-animation:blink-effect 1s step-end infinite;    
}

@keyframes blink-effect {
    0%{ opacity: 1; } 50%{ opacity: 0; } 
}
@-webkit-keyframes blink-effect {
    50% {
        opacity: 0;
    }
}
@-moz-keyframes blink-effect {
    50% {
        opacity: 0;
    }
}

.paper{
    border-radius: 10px;
    box-shadow: 3px 3px 20px #00000011;
}

.process_ul{
    display: flex;
    flex-direction: row;
    
}

.width80{
    width: 80%;
}
.width90{
    width: 90%;
}
.width100{
    width: 100%;
}

.description{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .screenshot{
        width: 520px;
        height: 375px;
        background-color: #724b3000;
        border-style: solid;
        border-width: 0px;
        border-color: #d3adff;
        border-radius: 30px;
        margin: 5px;
    }
    .subtitle{
        color: black;
        font-size: 2.5rem;
    }

    &.right_pic{
        flex-direction: row-reverse
    }
}

.id-card-img{
    width: 100%;
}


/* ============footer============ */
.iscilab_logo_img{
    width: 10rem;
}

.footer{
    font-family: $font-light;
    background-color: $blue-color;
    color: white;
    // padding: 100px;
}

.social_link img{
    width: 80%;
}


/* ============대문============ */


.main_section{
    top: 0px;
    left: 0px;
    position: relative;
    text-align:  left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    height: 100vh;
    width: 100vw;
    background-repeat : no-repeat;
    background-size : cover;
    background-image: url("../images/landing-page/landing-background.png");
}

.main_contents{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-img{
    max-width: 200px;
    min-width: 100px;
}

.main-img-qr{
    width: 85%;
}

.main-img-mobile{
    width: 70%;
    min-width: 270px;
    max-width: 450px;
}

.korea_mark{
    width: 2rem;
    margin-right: 0.5rem;
}

.font-light{
    font-family: $font-light !important;
}
.font-default{
    font-family: $font-default !important;
}
.font-bold{
    font-family: $font-bold !important;
}

.shadow_effect{
    text-shadow: 3px 3px 13px rgb(0, 0, 0);
}

.arrow {
    position: absolute;
    animation-name: arrow_ani;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes arrow_ani {
    from{
        bottom: 0;
    }

    to{
        bottom: 30px;
    }
}

.line{
    display: inline-block; 
    position: relative;
}
.line::after{
    content: "";
    display: block;
    height: 11px;
    margin-top: -10px;
    background-color: $light-purple-color;
}

.line_blue{
    text-decoration: underline;
    text-decoration-color: $blue-color;
    text-underline-position: under;
    text-decoration-thickness: 2px;
}

.blue{
    color: $blue-color;
    font-family: $font-default;
}

.blue_thin{
    color: $blue-color;
}
.row{
    display: flex;
    flex-direction: row;
}
.row.around{
    justify-content: space-around;
}
.row.between{
    justify-content: space-between;
}
.row.evenly{
    justify-content: space-evenly;
}

.padding100{
    padding: 10rem;
}
//===================section1============================
.section1_slider{
    width: inherit;
    margin-top: 2rem;
}

.slider_item{
    width: 95%;
    // padding: 1rem;
    object-fit: contain;
}

.process{
    .text{
        padding: 0 5rem 0 2rem;
    }
}

.img_process{
    width: 100%;
    max-width: 1200px;
}

.section4-button{
    width: 2.5rem;
}

.section6-img{
    width: 90%;
}

.carousel_div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.carousel{
display: flex;
flex-direction: row;
overflow: hidden;

.carousel-item{
    display: flex;
    direction: column;
    align-items: center
}

.section4-img{
    display: inline;
    align-items: center;
    max-height: 40rem;
    height: 80%;
}
}


// =========== bottom InstallationGuide===============
.app-install-buttons{
    // max-width: 400px;
    img{
        width: 10rem;
        margin-right: 1rem;
    }
}

//=====================contents============================
.num_img{
    width: 50%;
}
.app_screenshot{
    width: 50%;
}

.app_info{
    p:not(.bold){
        font-family: $font-light;
    }
}
.mgl5{
    margin-left: 5rem;
}
.mgr5{
    margin-right: 5rem;
}

.InfiniteLoop {
    @keyframes loop {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-300%);
        }
    }
    &__slider {
        overflow-x: hidden;
    }
    &__inner {
        display: flex;
        width: fit-content;
        animation: loop 3s 1s infinite;
        animation-timing-function: linear;
        animation-iteration-count: infinite ;
        animation-duration: 60s;
        & > * {
            flex-shrink: 0;
        }
    }
    .Main__best-item-img{
        // width: 30vw;
    }
}

// 태블릿?
@media screen and (max-width: 1024px){
    .main_section{
        max-width: 1024px;
        text-align: center;
    }
    .sub-slogan{
        justify-content: center;
    }
    .buttons {
        display: block;
    }
    .images img:not(.img_arrow) {
        margin: 1rem;
    }
    .img_arrow {
        width: 4rem;
        margin: 6rem 1rem;
    }
    
    

}
//==================== mobile =====================
@media screen and (max-width: 766px){
    .InfiniteLoop {
        @keyframes loop {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-50%);
            }
        }
        &__slider {
            overflow-x: hidden;
        }
        &__inner {
            display: flex;
            width: fit-content;
            animation: loop 3s 10s infinite;
            animation-timing-function: linear;
            animation-iteration-count: infinite ;
            animation-duration: 30s;
            & > * {
                flex-shrink: 0;
            }
        }
    }
}

// nowMediaQuery mobile tag
.mobile{
    .anipuppy_logo_img{
        width: 60%;
    }
    .cta-button{
        background-color: $blue-color;
        color: white;
        z-index: 999;
        padding: 2rem;
        text-align: center;
        font-size: 1.7rem;
        font-family: $font-bold;
        bottom: 4%;
        width: 80%;
        right:10%;
        left: 10%;
        display: flex;
        justify-content: center;
        position: fixed;
        border-radius: 10px;
    }
    .floating-button{
        bottom: 0px ;
        right: 0px ;
        left: 0px ;
        width: 100% ;
        border-radius: 0px;
        transition: right 380ms ease 0s, left, bottom, width;
    }
    .iscilab_logo_img{
        width: 10rem;
    }
    .img_process{
        max-width: 400px;
    }
    .contents{
        padding: 7rem 0;
        overflow-x: hidden;
    }
    .main_section{
        background-image: url("../images/landing-page/landing-background-s.png");
    }
    .Main__best-item-img-mobile{
        width: fit-content;
    }
    .slider_item{
        width: 200px;
        padding: 1rem;
        object-fit: contain;
    }
}

// 모바일 가로모드, 태블릿 사이즈
// @media only screen and(orientation: landscape) {
.tablet{

}
