.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: scroll;
}
.modal button {
  outline: none;
  cursor: pointer;
}
.modal > section {
  margin: 0 auto;
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modal > section > header {
  position: relative;
  padding: 10px 16px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
}
.modal > section > main > button {
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modal > section > main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
}
.modal > section > footer {
  padding: 5px;
}
.modal > section > main > .foot_close {
  padding: 3px 10px;
  color: #00539c;
  background-color: #fff;
  border-color: #00539c;
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  width: 8rem;
  top: -3.5rem;
  right: 1rem;
  position: relative;
  font-size: 1.2rem;
  align-self: end;
}
.modal.openModal {
  display: flex;
  align-items: center;
  animation: modal-bg-show 0.3s;
}

.mobile{
  .sandbox{
    width: 100%
  }
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (orientation: landscape) {
  .modal.openModal{
    align-items: baseline;
  }
}