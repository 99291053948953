.faq_front{
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 60rem;
  color: white;
  background-position: 65% 50%;
  display: flex;
  align-items: flex-start;
  object-fit: contain;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  >:first-child{
    margin-top: 10%;
    margin-left: 5%;
  }
}

.dividing_line{
  width: 100%;
  height: 2px;
  background-color: #4c86b9;
  margin: 2rem 0;
}

.faq_contents_section{
  padding: 6rem 8rem;
}

.faq_contents{
  .box{
    display: flex;
    flex-direction: row;
    justify-content: left;  
    align-items: center;
    font-size: 1.2rem;
    padding: 1rem;
    white-space: pre-line;
    line-height: 1.7;
    .text{
      padding-left: 1rem;
    }
  }

  .icon{
    width: 1.2rem;
    object-fit: contain;
    margin: 1rem;
    &.toggle{
      transition: all 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.show{
      transform : rotate(180deg);
    }
  }

  .question{
    cursor: pointer;
    border : 2px solid lightgray;
    justify-content: space-between;
    
    .question_left{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &.off:not(.is_last){
      border-bottom-style:none;
    }
  }
  .answer{
    display: flex;
    flex-direction: row;
    justify-content: left;  
    align-items: center;
    background-color: rgb(240, 240, 240);
    >.icon {
      width: 3rem !important;
      margin: 0.3rem;
    }
  }
  .faq_arrow_btn > .icon{
    width: 2rem;
  }
  .show-menu{
    display: block;
    animation: growDown 300ms ease-in-out alternate ;
  }
  .hide-menu{
    display: none;
  }
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  70% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}

.mobile{
  .faq_contents_section{  
    padding: 2rem 2rem;
  }
  .faq_contents{
    .box{
      padding: 1rem 1rem;
    }
    .icon{
      width: 1.5rem;
    }
    .answer > .icon {
      margin: 0.3rem;
    }
  }
}

.tablet{
  .faq_contents_section {
    padding: 4rem 8rem;
  }
}