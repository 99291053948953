@font-face {
  font-family: "NBGothic";
  src: url("../font/NanumBarunGothic.woff") format("woff");
}
@font-face {
  font-family: "NBGothicBold";
  src: url("../font/NanumBarunGothicBold.woff") format("woff");
}
@font-face {
  font-family: "NBGothicUL";
  src: url("../font/NanumBarunGothicUltraLight.woff") format("woff");
}
body {
  overflow-x: hidden;
}
html,
body {
  font-size: 15px;
  font-family: "NBGothic", sans-serif !important;
  /* width: 100vw; */
  max-width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  line-height: normal;
  word-wrap: break-word;
  background-color: #fff;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
strong,
.bold {
  font-family: "NBGothicBold", sans-serif !important;
}

header,
footer,
nav,
article,
aside,
hgroup,
section,
figure,
div,
p,
ul,
li,
ol {
  display: block;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
th,
td,
dt,
dd,
em,
span,
strong,
fieldset,
label,
a {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-family: "NBGothic", sans-serif !important;
}

a {
  text-indent: 0;
  color: inherit;
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

fieldset {
  border: none;
}

legend,
caption {
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  table-layout: fixed;
}

table td,
table th {
  padding: 0;
}

button {
  overflow: visible;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  background: none;
  border: none;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button span {
  position: relative;
  display: inline-block;
}

.ir {
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 0;
  clip: rect(0 0 0 0);
}

/* PC */
@media screen and (min-width: 768px) {
  .body {
    max-width: 1140px;
    padding: 50px;
  }
}

/* mobile */
@media screen and (max-width: 767px) {
  .body {
    max-width: 100vw;
    padding: 20px;
  }

  html {
    font-size: 10px;
  }
}
